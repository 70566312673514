/*
    Xpull - pull to refresh jQuery plugin for iOS and Android
*/
.xpull {
    display: none;
    height: 40px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    transform: translate3d(0, 0, 0) rotate(0deg);
}

.xpull_pulled .xpull__arrow {
    top: 5px;
    transform: rotate(180deg);
}

.xpull__start-msg-text {
    margin-bottom: 5px;
}

.xpull__arrow {
    position: relative;
    width: 4px;
    height: 10px;
    margin: 0 auto 6px;
    background-color: #cacaca;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    transition: transform 300ms ease;
}

.xpull__arrow::after {
    content: '';
    position: absolute;
    top: 100%;
    left: -3px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #cacaca;
}

.xpull__spinner {
    display: none;
    padding-top: 10px;
}

.xpull__spinner-circle {
    height: 25px;
    width: 25px;
    margin: 0 auto;
    position: relative;
    left: -4px;
    animation: rotation 0.6s infinite linear;
    border: 4px solid rgba(202, 202, 202, 0.15);
    border-top: 4px solid rgba(202, 202, 202, 0.9);
    border-radius: 100%;
}

@-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(359deg);}
}

@-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(359deg);}
}

@-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to {-o-transform: rotate(359deg);}
}

@keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
}